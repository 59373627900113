@fontface {
  font-family: "Poppins", cursive;
  src: url("https://fonts.googleapis.com/css?family=Poppins");
}

.navbar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #000000;
  padding: 10px 10px 10px 25px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

._navbar {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 10px 25px;
  gap: 2em;
  border: 1px solid rgba(164, 173, 255, 0.45);
  border-radius: 1000px;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: #FFFFFF;
}

.connect_wallet_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background: linear-gradient(223.27deg, #0026FF 0.32%, #FF9AFB 77.33%);
  border-radius: 100px;
  color: white;
  border: 0px;
}
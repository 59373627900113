.casino__machine {
  width: 100%;
  height: 355px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 3rem;
}

.casino__machine .machine-img {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.casino__machine .doors {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: -9px;
  margin-top: 28px;
}

.casino__machine .door {
  background: #fafafa;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.4) inset;
  width: 19%;
  height: 80px;
  overflow: hidden;
  border-radius: 1ex;
  margin: 1ch;
}

.casino__machine .box {
  transition: transform 1s ease-in-out;
  background-position-x: 55%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  height: 80px;
}

/* .casino__machine .box1 {
  background-image: url("../../assets/slot-icon-1.png");
}

.casino__machine .box2 {
  background-image: url("../../assets/slot-icon-2.png");
}

.casino__machine .box3 {
  background-image: url("../../assets/slot-icon-3.png");
}

.casino__machine .box4 {
  background-image: url("../../assets/slot-icon-4.png");
}

.casino__machine .box5 {
  background-image: url("../../assets/slot-icon-5.png");
}

.casino__machine .box6 {
  background-image: url("../../assets/slot-icon-6.png");
}

.casino__machine .box7 {
  background-image: url("../../assets/slot-icon-7.png");
}

.casino__machine .box8 {
  background-image: url("../../assets/slot-icon-8.png");
}

.casino__machine .box9 {
  background-image: url("../../assets/slot-icon-9.png");
}

.casino__machine .box10 {
  background-image: url("../../assets/slot-icon-10.png");
}

.casino__machine .box11 {
  background-image: url("../../assets/slot-icon-11.png");
}

.casino__machine .box12 {
  background-image: url("../../assets/slot-icon-12.png");
}

.casino__machine .box13 {
  background-image: url("../../assets/slot-icon-13.png");
}

.casino__machine .box14 {
  background-image: url("../../assets/slot-icon-14.png");
}

.casino__machine .box15 {
  background-image: url("../../assets/slot-icon-15.png");
} */

.winning {
  position: absolute;
  color: white;
  font-weight: 700;
  margin-top: -85px;
  font-size: 0.9rem;
}

#starshine {
  position: absolute;
  top: 12vh;
  left: 4vw;
  width: 70%;
  height: 10vh;
  z-index: 1;
  overflow: hidden;
}

.shine {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url(https://res.cloudinary.com/ahsan141/image/upload/v1666347538/download_bnl6vj.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  overflow: hidden;
  z-index: 2;
  color: tansparent;
  -moz-opacity: 0;
  opacity: 0;
  animation: glitter 6s linear 0s infinite normal;
  -webkit-animation: glitter 6s linear 0s infinite normal;
  -moz-animation: glitter 8s linear 0s infinite normal;
  -ms-animation: glitter 8s linear 0s infinite normal;
  -o-animation: glitter 8s linear 0s infinite normal;
}

.shine.small {
  width: 20px;
  height: 20px;
}

.shine.medium {
  width: 30px;
  height: 30px;
}

.shine.large {
  width: 50px;
  height: 50px;
}

/*CSS3 keyframes for glittering effect*/
@-webkit-keyframes glitter {
  0% {
    -webkit-transform: scale(0.3) rotate(0deg);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(1) rotate(360deg);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.3) rotate(720deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(0.3) rotate(0deg);
    opacity: 0;
  }
}
